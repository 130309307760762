.source {
  background-color: #033a68;
  /* Light grey background */
  border: 1px solid #01172a;
  /* Slightly darker grey border */
  color: white;
  border-radius: 20px;
  /* More rounded corners */
  width: fit-content;
  font-size: smaller;
  padding: 0.3em 0.6em;
  /* Combined padding for left and right */
  text-align: center;
  display: inline-block;
  /* Ensures proper sizing and alignment */
  transition: background-color 0.3s, opacity 0.3s, box-shadow 0.3s;
  /* Smooth transitions */

  &:hover {
    cursor: pointer;
    opacity: 0.9;
    /* Slightly change opacity */
    background-color: #001a33;
    /* Darker grey on hover */
  }
}
