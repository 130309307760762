.thumbs {
  color: var(--gray-400);
  font-size: 1.2rem;
  &:hover {
    cursor: pointer;
    scale: 1.2;
    transition: all 0.2s;
  }
}

.thumbsUp {
  &:hover {
    color: #27ae60;
  }
}

.thumbsDown {
  &:hover {
    color: #e74c3c;
  }
}
