.chatContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  padding-bottom: 1rem;
}

.activeProgressWrapper {
  width: 100%;
  max-width: 800px;
  margin: 0.5rem auto;
  padding: 0 1rem;
}

.progressSection {
  width: 100%;
  max-width: 800px;
  margin: 0.5rem auto;
  padding: 0 1rem;
}

.progressContainer {
  background: #f8f9fa;
  padding: 0.75rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.progressHeader {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fileProgress {
  padding: 0.4rem;
  margin-bottom: 0.25rem;
  background: white;
  border-radius: 6px;
  border: 1px solid #eee;
  
  &:last-child {
    margin-bottom: 0;
  }
}

.fileInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
  font-size: 0.85rem;
}

.fileName {
  font-size: 0.9rem;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}

.pageCount {
  font-size: 0.9rem;
  color: #666;
  white-space: nowrap;
}

:global(.tooltip) {
  opacity: 0.8 !important;
  border-radius: 20px !important;
  padding: 0 !important;
  transition: opacity 1000ms ease-in-out !important;
}

:global(.tooltip-inner) {
  background-color: rgba(0, 23, 49, 0.8) !important;
  color: #ffffff !important;
  border-radius: 20px !important;
  padding: 4px 8px !important;
  font-size: 0.7rem !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  max-width: 200px !important;
}

:global(.tooltip .tooltip-arrow::before) {
  border-top-color: rgba(0, 23, 49, 0.8) !important;
}

:global(.bs-tooltip-top .tooltip-arrow::before) {
  border-top-color: rgba(0, 23, 49, 0.8) !important;
}

:global(.bs-tooltip-bottom .tooltip-arrow::before) {
  border-bottom-color: rgba(0, 23, 49, 0.8) !important;
}


.tooltipContainer {
  position: relative;
  display: inline-block;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.modesIndicationsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
  min-height: auto;
}

.modesIndicationsItem {
  display: flex;
  border-top: 1px solid #D9D9D9;
  flex-direction: row;
  width: 100%;
  padding: 5px 0;
  cursor: pointer;
  &:hover {
    background-color: #D9D9D9;
  }
}

.chatInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  width: 100%;
}

.searchform {
  border-radius: 30px;
  border: 1px solid #D9D9D9;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 4px 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin: 0 1rem;
}

.inputWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding-right: 4px;
}

.textarea {
  border: none;
  outline: none;
  width: 100%;
  resize: none;
  padding: 4px 12px;
  font-size: 16px;
  flex-grow: 1;
  border-radius: 50px;
  min-height: 24px;
  max-height: 120px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  background-color: transparent;
}

.textarea::placeholder {
  color: #bbb;
}

.textarea:disabled {
  background-color: transparent;
}

.buttonGroup {
  display: flex;
  flex: 1;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}

.customButton {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  border-radius: 20px;
  height: 40px;
  padding: 8px 12px;
  color: grey;
  background-color: white;
  border: 1px solid #D9D9D9;
  &:hover {
    background-color: #DBE9FD;
  }
}

.buttonIcon {
  font-size: 16px;
}

.sendButton {
  border: none;
  background-color: black;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  &:hover {
    background-color: grey;
  }
}

.sendIcon {
  font-size: 18px;
  color: gray;
}

.controlsContainer {
  display: flex;
  align-items: center;
}

.plusButton {
  padding: 6px 12px;
  border-radius: 100px;
  border: 1px solid #D9D9D9;
  &:hover {
    background-color: #DBE9FD;
  }
}

.historySection {
  margin-top: 0.5rem;
}

.historyButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.4rem;
  background: none;
  border: none;
  color: #666;
  font-size: 0.85rem;
  cursor: pointer;
  
  &:hover {
    color: #333;
  }
}

.historyList {
  background: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
}

.historyItem {
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
  
  &:last-child {
    border-bottom: none;
  }
}

.completedFile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
  color: #666;
  
  .fileName {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1rem;
  }
}

:global(.progress) {
  height: 0.5rem !important;
  border-radius: 4px;
  background-color: #f0f0f0;
  
  :global(.progress-bar) {
    transition: width 0.3s ease;
    font-size: 0.7rem;
    line-height: 0.5rem;
    font-weight: 500;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    min-width: 2rem;
  }
}

.progressAndHistoryContainer {
  display: flex;
  gap: 0.5rem;
  width: 100%;
  max-width: 800px;
  padding: 0 1rem;
  margin-top: 0.5rem;
  border-top: 1px solid #D9D9D9;
  padding-top: 0.5rem;
}

.progressSection, .historySection {
  flex: 1;
  min-width: 0;
}

.toggleButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.25rem;
  background: none;
  border: none;
  color: #666;
  font-size: 0.85rem;
  cursor: pointer;
  margin-bottom: 0.25rem;
  
  &:hover {
    color: #333;
  }

  i {
    font-size: 0.9rem;
  }
}

.activeProgressWrapper, .historyList {
  padding: 0.5rem;
}

.fileProgress, .historyItem {
  padding: 0.25rem;
  margin-bottom: 0.25rem;
}

.fileName, .pageCount {
  font-size: 0.8rem;
}

.modesContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 1rem;
}