.multiSelectContainer {
  :global(.p-multiselect) {
    width: 100%;
  }
}

.formLabel {
  font-size: 12px;
} 

.labelContainer {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.infoIconWrapper {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: help;
}

.infoIcon {
  color: #6c757d;
  transition: color 0.3s ease;
  font-size: 0.75em;

  &:hover {
    color: #495057;
  }

  i {
    transition: all 0.3s ease;

    &::before {
      content: "\F431"; // Unicode for bi-info-circle
    }
  }

  &:hover i {
    &::before {
      content: "\F430"; // Unicode for bi-info-circle-fill
    }
  }
}

.small_multiselect_label {
  font-size: 12px !important;
}

/* .small_multiselect_token {
  font-size: 10px !important;
  padding: 2px 5px !important;
  height: 20px !important;
  line-height: 18px !important;
} */
