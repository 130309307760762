$primary: #031a3b;
$secondary: #999999;
$tertiary: #00afcd;
$dark: #000b21;
$light: #f6f6f6;
$danger: #ffebee; // Dark red color

$font-family-base: "Montserat", Arial, sans-serif;

:export {
    primary: $primary;
    secondary: $secondary;
    tertiary: $tertiary;
    dark: $dark;
    light: $light;
}

:root {
    --tertiary-color: #{$tertiary};
}

.custom-sidebar .tertiary-badge {
    background-color: $tertiary !important;
}
