@use './flex';
@use './utils';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.p-multiselect{
  font-size: small;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
	padding: 0.2rem 0.5rem;
	margin-right: 0.5rem;
	background: #e5e7eb;
	color: #4b5563;
	border-radius: 15px;
}
.sticky{
  position: static;
}

.bottom-0{
  top: 0px;
}

.height-full{
  height:100%;
}

.width-full{
  width:100%;
}