.step {
    margin-bottom: 2em;
    font-size: smaller;
}

.container {
    display: flex;
    align-items: center;
}

.label {
    font-size: 1em;
    font-style: italic;
    color: var(--bs-secondary);
    text-overflow: "...";
}

.result {
    border-left: 4px solid var(--bs-secondary);
    font-size: small;
    padding-left: 2rem;
    display: -webkit-box;
    max-width: 100%;
}
