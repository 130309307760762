@import 'assets/styles/variables';
@import '../node_modules/bootstrap';
@import 'assets/styles/primereact/mytheme/theme.scss';


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-sidebar {
  overflow-y: auto !important; /* Enable vertical scrolling */
}

/* Customize the scrollbar */
.custom-sidebar::-webkit-scrollbar {
  width: 7px;
}

.custom-sidebar::-webkit-scrollbar-thumb {
  border-radius: 8px; /* Adjust the border-radius for rounded edges */
  background-color: transparent;
}

.custom-sidebar::-webkit-scrollbar-track {
  background-color: none;
}

.custom-sidebar:hover::-webkit-scrollbar-thumb {
  background-color: rgba(136, 136, 136, 0.5); /* Adjust the alpha value for transparency */
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(136, 136, 136, 1); /* Change alpha to 1 on hover */
}

.nav-link-with-underline {
  position: relative;
  text-decoration: none !important; /* Remove default underline */
}

.nav-link-with-underline::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: white; /* Set the underline color to white */
  transition: width 0.3s ease; /* Add transition for a smooth effect */
}

.nav-link-with-underline:hover::before {
  width: 100%;
}

.nav-link-with-underline.active::before {
  width: 100%;
}

.nav-link-with-underline.active {
  font-weight: bold;
}
