.menuButton {
  font-size: 20px;
  border-width: 0px;
  color: #515151;
  
  &:hover {
    background-color: #D9D9D9;
    color: #515151;
  }
}

// Ajout des styles pour gérer la barre de défilement
:global(body) {
  overflow-y: scroll; // Force une barre de défilement permanente
  scrollbar-gutter: stable; // Réserve l'espace pour la barre de défilement
}

// Optionnel : pour cacher visuellement la barre de défilement tout en gardant la fonctionnalité
:global(body::-webkit-scrollbar) {
  width: 0px;
  background: transparent;
  visibility: hidden !important;
}

:global(body) {
  scrollbar-width: none; // Pour Firefox
  -ms-overflow-style: none; // Pour IE et Edge
}


