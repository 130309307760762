.menuContent {
  min-height: 100%;

  ::-webkit-scrollbar {
    display: none;
  }

  /* Pour Firefox */
  scrollbar-width: none;
}

.tab {
  display: inline-block;
  vertical-align: middle;
  align-items: center;
  height: 50px;
  text-align: center;
  font-size: 15px;
  padding: 0.2em; // Decreased padding from 0.5em to 0.2em
  width: 110px;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    // font-weight: bold;
    background-color: var(--gray-200);
    border-radius: 10px;
  }
  transition: all 0.2s;
}

.tabActive {
  background-color: var(--gray-300);
  border-radius: 10px;
  font-weight: bold;
}

.collapseButton {
  font-size: small;
  padding: 0.3em;
  border-radius: 10px;
  background-color: var(--gray-200);
  vertical-align: middle;
  align-items: center;
  &:hover {
    background-color: var(--gray-300);
    cursor: pointer;
  }
  transition: all 0.2s;
}

// New styles for the new thread button
.newThreadButton {
  cursor: pointer;
  padding: 8px 12px;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s;
  color: var(--gray-700);
  width: 50%;

  &:hover {
    background-color: var(--gray-200);
  }

  &:focus {
    outline: none;
  }

  i {
    font-size: 16px;
  }
}
