$primaryColor: #031A3B !default;
$primaryLightColor: #00AFCD !default;
$primaryDarkColor: #031A3B !default;
$primaryDarkerColor: #000B21 !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #ecfeff !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, 0.24) !default;

@import './variables';
@import './_fonts';
@import '../theme-base/_components';
@import './_extensions';