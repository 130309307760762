@import "../../assets/styles/variables.scss";

.signInButton {
    border-radius: 20px; // Makes the button have rounded corners (pill shape)
    padding: 0.375rem 0.75rem; // Adjust padding if necessary

    &:hover {
        background-color: $tertiary; // Change background color on hover if desired
        color: $light; // Change text color on hover if needed
        border-color: $tertiary; // Change border color on hover if needed
    }

    &:active {
        background-color: darken($tertiary, 10%); // Darker shade for active state
        color: $light;
        border-color: darken($tertiary, 10%);
    }
}
