.step {
    margin-bottom: 2em;
    font-size: smaller;
}

.container {
    width: 100%;
    padding: 15px;
}

.label {
    font-size: 1em;
    font-style: italic;
    color: var(--bs-secondary);
    text-overflow: "...";
}

.result {
    width: 100%;
    color: grey;
}

.header {
    font-size: 14px;
    color: grey;
    
    strong {
        color: grey;
    }
}

.buttonContainer {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
}

.optionButton {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  border-radius: 20px;
  height: 40px;
  padding: 8px 12px;
  color: grey;
  border: 1px solid #D9D9D9;
  background-color: white;
}

.btnSuccess {
  &:hover {
    background-color: #ddebdc !important;
  }
}

.btnDanger {
  &:hover {
    background-color: #faeaee !important;
  }
}

.textarea {
  width: 50%;
  min-height: 60px;
  padding: 8px;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  resize: none;
  overflow-y: hidden;
  color: grey;
  background-color: transparent;

  &:focus {
    outline: none;
    border-color: #D9D9D9;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
}

.textarea::placeholder {
  color: #bbb;
}

.textarea:disabled {
  background-color: white;
}