.label {
  font-weight: bold;
  min-width: 75px;
  max-width: 75px;
  color: var(--primary-color);
  font-size: smaller;
}

.source {
  background-color: #033a68; /* Light grey background */
  border: 1px solid #01172a; /* Slightly darker grey border */
  color: white;
  border-radius: 20px; /* More rounded corners */
  width: fit-content;
  font-size: smaller;
  padding: 0.3em 0.6em; /* Combined padding for left and right */
  text-align: center;
  display: inline-block; /* Ensures proper sizing and alignment */
  transition: background-color 0.3s, opacity 0.3s, box-shadow 0.3s; /* Smooth transitions */

  &:hover {
    cursor: pointer;
    opacity: 0.9; /* Slightly change opacity */
    background-color: #001a33; /* Darker grey on hover */
  }
}

.relevantSource {
  background-color: var(--primary-color); /* Example dark blue */
  border: 1px solid var(--primary-darker-color); /* Slightly darker border */

  &:hover {
    background-color: var(--tertiary-color); /* Darker primary color on hover */
  }
}

.source,
.relevantSource {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}
