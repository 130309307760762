.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 30px;
}

.p-30 {
  padding: 30px;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-15 {
  margin-right: 15px;
}

.my-30 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.container{
  max-width: 1200px;
  margin: auto;
  width: 100%
}

.layer{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}