.tableContainer {
    position: relative;
}

.exportButton {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent white background */
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition:
        background-color 0.3s,
        opacity 0.3s;
    opacity: 0; /* Start with the button hidden */
    pointer-events: none; /* Prevent interaction when hidden */
}

.tableContainer:hover .exportButton {
    opacity: 1; /* Show the button on hover */
    pointer-events: auto; /* Enable interaction */
}

.exportButton i {
    color: #217346; /* Excel green color */
    font-size: 20px; /* Adjust the size as needed */
}

.exportButton:hover {
    background-color: rgba(255, 255, 255, 1); /* Fully opaque white on hover */
}

@keyframes tableAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.02);
    }
    100% {
        transform: scale(1);
    }
}

.animateTable {
    animation: tableAnimation 0.5s ease-in-out;
}
