@import "../../assets/styles/variables.scss";

.userInitials {
    width: 40px;
    height: 40px;
    background-color: $tertiary; // Use the primary color from your palette
    color: var(--light-color); // Use the light color for text
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; // Indicates it's clickable
    transition:
        background-color 0.3s ease,
        box-shadow 0.3s ease; // Smooth transition for effects

    &:hover {
        background-color: darken($tertiary, 10%); // Darker shade of the primary color for hover
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Subtle shadow for depth
    }

    &:active {
        background-color: darken($tertiary, 20%); // Even darker shade for active state
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); // Smaller shadow for pressed state
    }
}
