.message {
    margin-bottom: 2em;
    font-size: small;
}

.type {
    font-weight: bold;
    min-width: 75px;
    max-width: 75px;
}

.result {
    font-size: small;
    background-color: white; /* Light grey background */
    border-radius: 8px; /* Slightly rounded corners */
    padding: 10px; /* Optional: some padding inside the div */
}
