.landing-page {
    display: flex;
    flex-grow: 1;
    background: url('../../assets/images/circle.jpg') center/cover no-repeat; /* Set the background image */
    position: relative;
  }
  
  .overlay {
    flex-grow: 1;
    display: flex;
    background-color: rgba(0, 0, 0, 0); /* Adjust the overlay color and opacity as needed */
  }
  
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white; /* Set the text color */
    padding: 20px; /* Adjust the padding as needed */
  }
  
  /* Additional styling for the text if required */
  h1 {
    font-size: 2em;
  }
  
  p {
    font-size: 1em;
  }