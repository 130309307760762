.carousel-inner {
    height: 100%;
}
.carousel-control-prev {
    justify-content: start !important;
    width: fit-content !important;
}

.carousel-control-next {
    justify-content: end !important;
    width: fit-content !important;
}
