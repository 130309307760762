.thread {
  font-size: smaller;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 8px;
  white-space: nowrap;
  flex-shrink: 0;
  &:hover {
    cursor: pointer;
    background-color: var(--gray-200);
    border-radius: 10px;
  }
}

.threadActive {
  background-color: var(--gray-300);
  border-radius: 10px;
}

.searchInput {
  padding: 0 1rem;
  padding-left: 1rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  background-color: white;
  color: #0d0c22;
  transition: 0.3s ease;
  &::placeholder {
    color: #9e9ea7;
  }
}
