.fileSelectorComponent {
  display: flex;
  flex-direction: row;
  min-width: 0;
  flex: 1;
  max-width: 100%;
  font-size: smaller;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  padding: 8px;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */

  &:hover {
    cursor: pointer;
    background-color: var(--gray-200);
    border-radius: 10px;
  }
}

.threadActive {
  background-color: var(--gray-300);
  border-radius: 10px;
}

.searchInput {
  padding: 0 1rem;
  padding-left: 1rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  background-color: white;
  color: #0d0c22;
  transition: 0.3s ease;
  &::placeholder {
    color: #9e9ea7;
  }
}

.headerContainer {
  position: sticky;
  top: 0;
  background-color: inherit;
  z-index: 1;
  padding: 1rem;
  padding-right: 1.5rem;
  border-bottom: 1px solid var(--gray-200);
}

.tooltip {
  font-size: 0.75rem;
  background-color: var(--gray-800);
  color: white;
  border-radius: 50px;
  opacity: 0.8;
}
