.buttonText {
  font-size: small;
}

.alertContainer {
  width: 100%; // Set to desired width
  height: 100%; // Set to match the loader height
  display: flex;
  align-items: center; // Center content vertically
  justify-content: center; // Center content horizontally
}

@container (width <= 300px) {
  .buttonText {
    font-size: larger;
    font-weight: bolder;
  }
}

@container (width > 300px) {
  .buttonText {
    font-size: smaller;
    font-weight: lighter;
  }
}
