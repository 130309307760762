.navLink {
  position: relative;
  color: white !important; // Ensure text color is white

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  &:hover::after,
  &.active::after {
    transform: scaleX(1);
  }
}

.active {
  font-weight: bold;
}
